<i18n locale="th" lang="yaml" >
drone_manufacture.field.model : "รุ่น"
drone_manufacture.field.serial_no : "S/N"
drone_manufacture.field.pixhawk_serial_no : "Pixhawk S/N"
drone_manufacture.field.manufacture_date : "วันผลิต"
drone_manufacture.field.detail : "รายละเอียด"
drone_manufacture.field.drone_name : "ชื่อ Drone"
drone_manufacture.field.lot_name : "Lot"
drone_manufacture.field.remote : "Remote Controller"
drone_manufacture.field.created_datetime : "วันที่ใส่ข้อมูล"

drone_manufacture.field.remote_sku : "SKU"
drone_manufacture.field.remote_serial_no : "S/N"
</i18n>

<template>
	<a-table
		bordered class="mytable drone-manufacture-table mytable-strip"
		:row-key="record => record.id"
		:row-class-name="rowClassName"
		:data-source="manufactures"
		:pagination="false"
		size="small">
		<a-table-column
			:title="$t('drone_manufacture.field.serial_no')"
			data-index="serial_no"
			:width="120">
			<template slot-scope="text,record">
				<InputWithFeedback
					v-if="canUpdate"
					v-model="record.serial_no"
					:disabled="isDeleted(record.id)"
					:error="getError(record.id,'serial_no')"
						:warning="getWarning(record.id,'serial_no')"
					allow-clear/>
				<span v-else>
					<DataWithFeedback
						:content="record.serial_no"/>
				</span>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('drone_manufacture.field.pixhawk_serial_no')"
			data-index="pixhawk_serial_no"
			:width="150">
			<template slot-scope="text,record">
				<InputWithFeedback
					v-if="canUpdate"
					v-model="record.pixhawk_serial_no"
					:disabled="isDeleted(record.id)"
					:error="getError(record.id,'pixhawk_serial_no')"
					:warning="getWarning(record.id,'pixhawk_serial_no')"
					allow-clear/>
				<span v-else>
					<DataWithFeedback
						:content="record.pixhawk_serial_no"/>
				</span>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('drone_manufacture.field.manufacture_date')"
			data-index="manufacture_date"
			:width="115">
			<template slot-scope="text">
				{{text | date}}
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('drone_manufacture.field.detail')"
			:width="120">
			<template slot-scope="record">
				<div v-if="canUpdate">
					<div class="input-block">
						<label>{{$t('drone_manufacture.field.lot_name')}}</label>
						<InputWithFeedback
							v-model="record.lot_name"
							:disabled="isDeleted(record.id)"
							:error="getError(record.id,'lot_name')"
							:warning="getWarning(record.id,'lot_name')"
							allow-clear/>
					</div>
					<div class="input-block">
						<label>{{$t('drone_manufacture.field.drone_name')}}</label>
						<InputWithFeedback
							v-model="record.drone_name"
							:disabled="isDeleted(record.id)"
							:error="getError(record.id,'drone_name')"
							:warning="getWarning(record.id,'drone_name')"
							allow-clear/>
					</div>
				</div>
				<div v-else>
					<div class="input-block view">
						<label>{{$t('drone_manufacture.field.lot_name')}}</label>
						<span class="input-value">{{record.lot_name | emptyCheck}}</span>
					</div>
					<div class="input-block view">
						<label>{{$t('drone_manufacture.field.drone_name')}}</label>
						<span class="input-value">{{record.drone_name | emptyCheck}}</span>
					</div>
				</div>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('drone_manufacture.field.remote')"
			:width="150">
			<template slot-scope="record">
				<div v-if="canUpdate">
					<div class="input-block">
						<label>{{$t('drone_manufacture.field.remote_sku')}}</label>
						<InputWithFeedback
							v-model="record.remote_sku"
							class="input-value"
							:disabled="isDeleted(record.id)"
							:error="getError(record.id,'remote_sku')"
							:warning="getWarning(record.id,'remote_sku')"
							allow-clear/>
					</div>
					<div class="input-block">
						<label>{{$t('drone_manufacture.field.remote_serial_no')}}</label>
						<InputWithFeedback
							v-model="record.remote_serial_no"
							class="input-value"
							:disabled="isDeleted(record.id)"
							:error="getError(record.id,'remote_serial_no')"
							:warning="getWarning(record.id,'remote_serial_no')"
							allow-clear/>
					</div>
				</div>
				<div v-else>
					<div class="input-block view">
						<label>{{$t('drone_manufacture.field.remote_sku')}}</label>
						<span class="input-value">{{record.remote_sku | emptyCheck}}</span>
					</div>
					<div class="input-block view">
						<label>{{$t('drone_manufacture.field.remote_serial_no')}}</label>
						<span class="input-value">{{record.remote_serial_no | emptyCheck}}</span>
					</div>
				</div>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('drone_manufacture.field.created_datetime')"
			:width="115">
			<template slot-scope="text,record">
				{{record.created_datetime | date}}
			</template>
		</a-table-column>
		<a-table-column
			v-if="canUpdate"
			:width="45"
			align="center">
			<template slot-scope="record">
				<a-button v-if="isDeleted(record.id)" icon="check" type="link"
					@click="handleUndoDelete(record.id)" />
				<a-button v-else icon="delete" class="text-error" type="link"
					@click="handleDelete(record.id)" />
			</template>
		</a-table-column>
	</a-table>
</template>

<script>
import {Table} from "ant-design-vue"
import InputWithFeedback from "@components/input/InputWithFeedback.vue"
import DataWithFeedback from "@components/common/DataWithFeedback.vue"
import ModelError from "@utils/errors/ModelError"
export default {
	components : {
		"a-table" : Table ,"a-table-column" : Table.Column ,
		InputWithFeedback, DataWithFeedback,
	} ,
	props : {
		manufactures : {
			type : null,
			default : () => []
		} ,
		deleteList : {
			type : Array,
			default : () => []
		} ,
		droneModel : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean,
			default : false,
		} ,
		modelError : {
			type : null,
			default : () => []
		} ,
		modelWarning : {
			type : null,
			default : () => []
		}
	} ,
	data() {
		return {
			hasChangeLot : false,
			currentLotName : '' ,
		}
	} ,
	methods : {
		getError(id,field) {
			if (this.modelError instanceof ModelError) {
				const error = this.modelError.getFieldErrorsByModelKey(id)
				if (error)  {
					if (field in error.field_errors) {
						return error.field_errors[field].join(', ')
					}
				}
			}
			return undefined
		} ,
		getWarning(id,field) {
			if (this.modelWarning instanceof ModelError) {
				const error = this.modelWarning.getFieldErrorsByModelKey(id)
				if (error)  {
					if (field in error.field_errors) {
						return error.field_errors[field].join(', ')
					}
				}
			}
			return undefined
		} ,
		isDeleted(id) {
			return this.deleteList.indexOf(id) >= 0
		} ,
		rowClassName(record,index) {
			if (this.isDeleted(record.id)) {
				return "inactive"
			} else {
				return ""
			}
		} ,
		handleUndoDelete(id) {
			this.$emit('undo-delete',id)
		} ,
		handleDelete(id) {
			this.$emit('delete',id)
		}
	}
}
</script>

<style lang="less" scoped>
.input-block {
	margin-bottom :  8px;
	label  {
		color : @info-color;
	}

	&:last-child {
		margin-bottom : 0;
	}

	&.view {
		label::after {
			content :" : "
		}
	}
}
.drone-manufacture-table {
	min-width : 1024px;
}
</style>

<i18n locale="th" lang="yaml" >
manufacture.action.auto-fill.label : "ใส่ข้อมูล"
manufacture.action.clear.label : "ล้างข้อมูล"

manufacture.model.title : "ผลิตสำหรับ Drone รุ่น {model}"
manufacture.model.empty : "ไม่มีพบการผลิต Drone Model นี้แล้ว"

action.save.label : "บันทึก"
action.save.confirm.title : "ยืนยันการบันทึกค่าการผลิต Drone"
action.save.confirm.message : "คุณต้องการบันทึกเปลี่ยนแปลงค่าของการผลิต Drone นี้ ?"
action.save.confirm.success : "ทำการบันทึกการผลิตของ Drone สำหรับ {manufacturer} เรียบร้อย"

action.create.label : "สร้าง Drone ตามรายการ"
action.create.confirm.title : "ยืนยันการสร้าง Drone"
action.create.confirm.message : "คุณต้องการสร้าง Drone จากการผลิตของ {manufacturer} นี้ ?"
action.create.confirm.success : "ทำการสร้าง Drone เรียบร้อย"


</i18n>

<template>
	<div class="manufacture-pane">
		<div class="header">
			<span class="title">
				{{$t('manufacture.model.title',{ model: droneModel.name })}}
			</span>
			<div v-if="canUpdate && $notEmpty(dataList)" class="actions">
				<a-button size="small"
					@click="openManufactureModal">
					{{$t('manufacture.action.auto-fill.label')}}
				</a-button>
				<a-button size="small" @click="clearManufactureAutoFill">
					{{$t('manufacture.action.clear.label')}}
				</a-button>
			</div>
		</div>

		<ErrorMessagePane :error="error"/>

		<ManufactureTable
			v-if="$notEmpty(dataList)"
			:manufactures="dataList"
			:can-update="canUpdate"
			:drone-model="droneModel"
			:delete-list="deleteList"
			:model-error="modelError"
			:model-warning="modelWarning"
			@delete="handleDeleteManufacture"
			@undo-delete="handleUndoDeleteManufacture"/>
		<div v-else class="empty">
			{{$t('manufacture.model.empty')}}
		</div>

		<div v-if="canUpdate && $notEmpty(dataList)" class="manufacture-actions">
			<a-button @click="handleSave">
				{{$t('action.save.label')}}
			</a-button>
			<a-button type="primary" @click="handleCreate">
				<a-icon type="plus" />
				{{$t('action.create.label')}}
			</a-button>
		</div>

		<ManufactureFillModal v-if="canUpdate"
			ref="modalRef"
			@submit="handleModalSubmit" />
	</div>
</template>

<script>
import numeral from "numeral"
import ManufactureTable from "@components/manufacture/ManufactureTable.vue"
import ManufactureFillModal from "@components/manufacture/ManufactureFillModal.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
import ModelError from '@utils/errors/ModelError'
import ApiError from '@/src/utils/errors/ApiError'
export default {
	components : {
		ManufactureFillModal , ErrorMessagePane,
		ManufactureTable ,
	} ,
	mixins : [PageMixin] ,
	props : {
		canUpdate : {
			type : Boolean,
			default : false,
		} ,
		droneModel : {
			type : null,
			default : () => []
		} ,
		manufacturer : {
			type : String,
			default : undefined,
		} ,
		manufactures : {
			type : null,
			default : () => []
		} ,
		initialWarnings : {
			type : null,
			default : () => []
		}
	} ,
	data() {
		return {
			oldList : [] ,

			deleteList : [] ,
			dataList : [],
			currentAutoFills : {
				lotName : undefined,
				startNo : undefined,
			},
			error : undefined,
			modelError : undefined,
			modelWarning : undefined,
		}
	} ,
	watch : {
		manufactures : {
			deep: true,
			handler() {
				this.initial(this.manufactures,true)
			}
		}
	} ,
	mounted() {
		this.initial(this.manufactures,true)
	} ,
	methods : {
		initial(manufactures,firstTime=false) {
			this.dataList = []
			this.oldList = []
			this.error = undefined
			if (firstTime)
				this.modelWarning = this.initialWarnings
			else
				this.modelWarning = this.initialWarnings

			this.modelError = undefined
			manufactures.forEach((m)=> {
				if (m.manufacturer_code != this.manufacturer || m.drone_model_id != this.droneModel.id) {
					return
				}
				this.dataList.push({...m})
				this.oldList.push({...m})
			})
			this.currentAutoFills.lotName = undefined
			this.currentAutoFills.startNo = undefined
		} ,
		openManufactureModal() {
			this.$refs.modalRef.openModal(this.manufacturer,this.droneModel,this.currentAutoFills)
		} ,
		clearManufactureAutoFill() {
			this.dataList.forEach((m) => {
				const oManufacture = this.oldList.find((om) => om.id == m.id)
				if (oManufacture) {
					m.serial_no = oManufacture.serial_no
					m.pixhawk_serial_no = oManufacture.pixhawk_serial_no
				}
				m.lot_name = undefined
				m.drone_name = undefined
				this.handleUndoDeleteManufacture(m.id)
			})
			this.currentAutoFills.lotName = undefined
			this.currentAutoFills.startNo = undefined
		} ,
		handleModalSubmit(payload) {
			const formValues = payload.form

			let droneNo = formValues.startNo
			let currentDroneNo = this.currentAutoFills.startNo
			this.dataList.forEach((m) => {
				if (this.$notEmpty(formValues.lotName)) {
					if ((this.$notEmpty(this.currentAutoFills.lotName) && this.currentAutoFills.lotName == m.lot_name) || (!this.$notEmpty(m.lot_name))) {
						m.lot_name = formValues.lotName
					}
				}
				if (droneNo != undefined) {
					if (currentDroneNo != undefined) {
						// Check
						const currentDroneName = this.droneModel.drone_name_prefix + numeral(currentDroneNo++).format('000')
						if (this.$notEmpty(m.drone_name) && m.drone_name == currentDroneName) {
							m.drone_name = this.droneModel.drone_name_prefix + numeral(droneNo++).format('000')
						}
					} else {
						m.drone_name = this.droneModel.drone_name_prefix + numeral(droneNo++).format('000')
					}
				}
			})
			this.currentAutoFills.lotName = formValues.lotName
			this.currentAutoFills.startNo = formValues.startNo
		} ,
		handleDeleteManufacture(id) {
			if (this.deleteList.indexOf(id) == -1) {
				this.deleteList.push(id)
			}
		} ,
		handleUndoDeleteManufacture(id) {
			if (this.deleteList.indexOf(id) >= 0) {
				this.deleteList.splice(this.deleteList.indexOf(id),1)
			}
		} ,
		handleSave() {
			if (!this.canUpdate)
				return

			this.$confirm({
				title : this.$t('action.save.confirm.title',{manufacturer : this.manufacturer}) ,
				content : this.$t('action.save.confirm.message',{manufacturer : this.manufacturer}) ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.showPageLoading(true)
					const formData = {
						manufacturer : this.manufacturer,
						manufactures : this.dataList,
						delete_list : this.deleteList,
					}
					this.error = undefined
					this.modelError = undefined
					axios.post("/api/manufacture/save",formData).then((response) => {
						this.initial(response.data.data.manufactures)
						if (response.data.data.warning) {
							this.modelWarning = new ModelError(new Error(),response.data.data.warning)
						} else {
							this.modelWarning = undefined
						}

						this.$message.success(this.$t('action.save.confirm.success',{'manufacturer' : this.manufacturer}))
					}).catch((err)=>{
						if (err instanceof ModelError) {
							this.$message.error(ApiError.getDisplayErrorMessage(this,err))
							this.modelError = err
						} else {
							this.error = err
						}
					}).finally(() => {
						this.hidePageLoading()
					})
				}
			})
		} ,
		handleCreate() {
			if (!this.canUpdate)
				return
			this.$confirm({
				title : this.$t('action.create.confirm.title',{manufacturer : this.manufacturer}) ,
				content : this.$t('action.create.confirm.message',{manufacturer : this.manufacturer}) ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					const formData = {
						manufacturer : this.manufacturer,
						manufactures : this.dataList,
						delete_list : this.deleteList,
					}
					this.showPageLoading(true)
					axios.post("/api/manufacture/create",formData).then((response) => {
						this.initial([])
						this.$message.success(this.$t('action.create.confirm.success',{'manufacturer' : this.manufacturer}))
					}).catch((err)=>{
						this.error = err
					}).finally(() => {
						this.hidePageLoading()
					})
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.manufacture-pane {
	margin-bottom : 32px;
	.header {
		margin-bottom : 16px;
		.title {
			font-family: @font-family-title;
			color : @secondary-color;
			text-decoration: underline;
		}
		.actions {
			display : inline-block;
			margin-left : 24px;
			> button {
				font-size : 0.95em;
				min-width : 85px;
			}
		}
	}
}
.manufacture-actions {
	margin-top : 12px;

}
</style>

<i18n locale="th" lang="yaml" >
page.title : "รายการ Drone ที่อยู่ระหว่างผลิต"
page.description : "รายการของ Drone ที่ถูกผลิต โดยรับข้อมูลมาจาก HGDKit ซึ่งนำข้อมูลมาจากโรงงาน โดยผู้ใช้งานจะต้องทำการระบุ Lot และทำการ Approve เพื่อนำข้อมูลเหล่านี้เพิ่มเป็นฐานข้อมูลของ Drone ใหม่"

manufacture.manufacturer.title : "ข้อมูลการผลิตจาก โรงงาน {code}"
manfacture.empty : "ไม่มีข้อมูลการผลิต Drone ชุดใหม่"
</i18n>

<template>
	<div v-if="!loading" class="page page-padding">
		<my-page-header :title="$t('page.title')"
			:description="$t('page.description')"/>
		<div v-if="$notEmpty(manufactureDataList)" class="manufacture-parent">
			<div v-for="(modelManufactures,manufacturer) in manufactureDataList" :key="manufacturer" class="manufacture-container">
				<div class="title">
					<ManufactureIcon class="anticon" />
					{{$t('manufacture.manufacturer.title',{ code: manufacturer })}}
				</div>

				<ManufacturePane v-for="(manufacturesList,modelId) in modelManufactures" :key="modelId"
					:can-update="canUpdate"
					:drone-model="getDroneModel(modelId)"
					:manufacturer="manufacturer"
					:initial-warnings="initialWarnings"
					:manufactures="manufacturesList"/>
			</div>
		</div>
		<div v-else class="manufacture-container empty">
			{{$t('manfacture.empty')}}
		</div>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import {lookup} from "@utils/objectUtil"
import ModelError from "@utils/errors/ModelError"
import axios from "axios"
import ManufactureIcon from "vue-material-design-icons/Factory.vue"
import ManufacturePane from "@components/manufacture/ManufacturePane.vue"

export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	components : {
		ManufactureIcon,ManufacturePane ,
	} ,
	mixins : [PageMixin] ,
	data() {
		return {
			manufactureDataList : {},
			bucket : {} ,
			initialWarnings : [] ,

			loading : false,
		}
	} ,
	computed : {
		canUpdate() {
			return this.$authorize('update', 'manufacture')
		} ,
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			this.showPageLoading()
			this.loading = true
			axios.get("/api/manufacture/list").then((response) => {
				const manufactures = response.data.data.manufactures
				this.bucket = response.data.data.bucket
				if (response.data.data.warning) {
					this.initialWarnings =new ModelError(new Error(),response.data.data.warning)
				} else {
					this.initialWarnings = undefined
				}

				const _manufactureDataList = {}
				manufactures.forEach((m) => {
					const manufacturer = m.manufacturer_code
					if (!(manufacturer in _manufactureDataList)) {
						_manufactureDataList[manufacturer] = {}
					}
					if (!(m.drone_model_id in _manufactureDataList[manufacturer])) {
						_manufactureDataList[manufacturer][m.drone_model_id] = []
					}
					_manufactureDataList[manufacturer][m.drone_model_id].push(m)
				})
				this.manufactureDataList = {..._manufactureDataList}
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
				this.hidePageLoading()
			})
		} ,
		getDroneModel(id) {
			return lookup(this.bucket,'models',id)
		} ,


	}
}
</script>

<style lang="less" scoped>
.manufacture-container {
	background : @white;
	padding : 16px 32px;
	margin-bottom : 24px;

	.title {
		font-family: @font-family-title;
		font-size : 1.2em;
		margin-bottom : 16px;
		color : @primary-color;
	}

	&.empty {
		color : @error-color;
		font-size : 1.2em;
		font-family: @font-family-title;
		text-align : center;
		padding-top : 48px;
		padding-bottom : 48px;
	}
}
</style>

<i18n locale="th" lang="yaml" >
manufacture.modal.title : "ข้อมูลการผลิต"
manufacture.modal.field.lot_name : "Lot"
manufacture.modal.field.start_no : "ตัวเลขชื่อ Drone"
manufacture.modal.field.start_no.help : "ตัวเลขชื่อ Drone โดยจะนับเริ่มจากเลขนี้ (ถ้าไม่กรอกคือ ระบุเอง)"
manufacture.modal.title.model : "สำหรับการผลิต Drone รุ่น {model}"
</i18n>

<template>
	<a-modal
		:visible="visible"
		:title="$t('manufacture.modal.title')"
		:ok-text="$t('common.save')"
		:width="400"
		class="manufacture-modal" :mask-closable="true"
		@ok="handleOk"
		@cancel="handleCancel">
		<div class="title">
			{{$t('manufacture.modal.title.model',{ 'model': droneModel.name })}}
		</div>
		<a-form :form="formObj">
			<a-form-item :label="$t('manufacture.modal.field.lot_name')">
				<a-input v-decorator="['lotName']" autocomplete="off"
					allow-clear/>
			</a-form-item>
			<a-form-item :label="$t('manufacture.modal.field.start_no')"
				:extra="$t('manufacture.modal.field.start_no.help')">
				<span class="ant-form-text">
					{{droneModel.drone_name_prefix}}
				</span>
				<a-input-number v-decorator="['startNo']" :min="1"/>
			</a-form-item>
		</a-form>
	</a-modal>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import {InputNumber} from "ant-design-vue"
import { updateFieldsValue } from '@utils/formUtil'
export default {
	components : {
		"a-input-number" : InputNumber
	} ,
	mixins : [HasAntdFormMixin] ,
	props : {

	} ,
	data() {
		return {
			visible : false,
			manufacturer : undefined,
			droneModel : {} ,
		}
	} ,
	methods : {
		openModal(manufacturer,droneModel,values) {
			this.visible = true
			this.manufacturer = manufacturer
			this.droneModel = droneModel


			updateFieldsValue(this.formObj,{
				lotName : undefined,
				startNo : undefined,
				...values,
			})
		} ,
		closeModal() {
			this.visible = false
		} ,
		handleOk() {
			this.formObj.validateFields((err,values) => {
				if (err)
					return
				this.$emit('submit',{
					drone_model_id : this.droneModel.id,
					manufacturer : this.manufacturer ,
					form : {
						...values
					}
				})
				this.closeModal()
			})
		} ,
		handleCancel() {
			this.closeModal()
		} ,
	}
}
</script>

<style lang="less" scoped>
.manufacture-modal {
	.title {
		font-size : 1.2em;
		color : @primary-color;
		font-family: @font-family-title;
	}
}
</style>
